import { Component, OnInit, Injector, Inject } from '@angular/core';
import { AbstractComponent, Attendance } from 'lib-trend-core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CrmPipedriveComponent } from './crm-pipedrive/crm-pipedrive.component';

@Component({
  selector: 'integrations-component',
  templateUrl: 'crm-integrations.component.html'
})
export class CrmIntegrationsComponent extends AbstractComponent implements OnInit {
  step: number = 0;
  dialogRef: MatDialogRef<any>;

  ngOnInit(): void { }

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public attendance: Attendance,
    private matDialogRef: MatDialogRef<CrmPipedriveComponent>
  ) {
    super(injector);
  }

  clearIntegrationBack() {
    this.step = 0
  }

  nextStepLeadsOne() {
    this.step = 1;
  }

  openDialogCrm(nextStepLeadsOne: number, attendance): void {
  
    const formattedAttendance = attendance.company ? attendance : { company: attendance._id };
  
    if (this.matDialogRef) {
      this.matDialogRef.close();
    }
  
    this.dialogRef = this.dialog.open(CrmPipedriveComponent, {
      width: '700px',
      minHeight: '400px',
      data: { nextStepLeadsOne, ...formattedAttendance }
    });
  
    this.dialogRef.afterOpened().subscribe(() => {
      this.dialogRef.componentInstance.nextStepLeadsOne();
    });
  }
  
}