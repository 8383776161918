import { AfterViewInit, Component, ElementRef, HostListener, Injector, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AbstractComponent, AlertModalComponent, Attendance, AttendanceService, AttendanceStatusEnum, AudioService, BottomSheetResponseFastComponent, CallPhoneComponent, Channel, ChannelService, ChannelTypeEnum, ConfirmationComponent, Contact, ContactListModalComponent, ContactService, FirebaseService, GoogleMapsComponent, GooglePlaceType, LoadMessageService, Message, MessageHelper, MessageScheduled, MessageService, MessageTypeEnum, MetadataMessage, ModalResponseFastComponent, Pager, PreviewMediaComponent, ResponseService, SettingsModule, SocketService, TabService, Traduct, UploadTypeEnum, UserService, UtilHelper } from 'lib-trend-core';
import { catchError, forkJoin, Observable, of, takeUntil, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AttendanceHistoryComponent, AttendanceHistoryData } from '../../attendance-history/attendance-history.component';
import { AiAssistantActivatorSelectorComponent } from '../attendance-panel-components/ai-assistant-activator-selector/ai-assistant-activator-selector.component';
import { AssignedUserComponent } from '../attendance-panel-components/assigned-user/assigned-user.component';
import { ChatMessageScheduleComponent } from '../attendance-panel-components/chat/chat-messages/chat-message-schedule/chat-message-schedule';
import { ChatMessageScheduleListComponent } from '../attendance-panel-components/chat/chat-messages/chat-message-schedule/chat-message-schedule-list/chat-message-schedule-list';
import { ChatTemplateScheduleComponent } from '../attendance-panel-components/chat/chat-messages/chat-template-schedule/chat-template-schedule.component';
import { ChatSelectTemplateComponent } from '../attendance-panel-components/chat/chat-select-template/chat-select-template.component';
import { CrmIntegrationsComponent } from '../attendance-panel-components/crm-integrations/crm-integrations.component';
import { TagAttendanceComponent } from '../attendance-panel-components/tag-attendance/tag-attendance.component';
import { AttendancePanelInfoComponent } from '../attendance-panel-info/attendance-panel-info.component';
import { getAttendance, getAttendances, getMessages, getNotes, getTags, openCloseSidebarRight, setAttendance } from '../state/actions';
import { AppState, PagerParamsState } from '../state/app.state';
import { attendanceSelector, hasPipedriveIntegrationSelector, messagesSelector, openCloseSidebarRightSelector, pagerParamsSelector, settingsModulesSelector } from '../state/selectors';

@Component({
  selector: 'attendance-panel-chat-component',
  templateUrl: './attendance-panel-chat.component.html',
  styleUrls: ['./attendance-panel-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AttendancePanelChatComponent extends AbstractComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('btnUpdateChatFromSocket') btnUpdateChatFromSocket: ElementRef;
  @ViewChild('btnUpdateMessagesFromSocket') btnUpdateMessagesFromSocket: ElementRef;

  @ViewChild('transferModal') transferModal: TemplateRef<any>;
  @ViewChild('inputImagefile', { static: false }) inputImagefile: ElementRef<HTMLInputElement>;
  @ViewChild('inputVideofile', { static: false }) inputVideofile: ElementRef<HTMLInputElement>;
  @ViewChild('inputDocfile', { static: false }) inputDocfile: ElementRef<HTMLInputElement>;
  @ViewChild('inputAudiofile', { static: false }) inputAudiofile: ElementRef<HTMLInputElement>;
  @ViewChild(AttendancePanelInfoComponent) panelInfo: AttendancePanelInfoComponent;
  @ViewChild('messageContainer') messageContainer: ElementRef<HTMLDivElement>;
  @ViewChild('audioContainer', { static: false }) audioContainer!: ElementRef;
  @ViewChild("emojiContainer", { static: false, read: ElementRef }) emojiContainer!: ElementRef

  readonly attendance$: Observable<Attendance> = this.store.select(attendanceSelector);
  readonly messages$: Observable<Pager<Message>> = this.store.select(messagesSelector);
  readonly pagerParams$: Observable<PagerParamsState> = this.store.select(pagerParamsSelector);
  readonly sidebarRight$: Observable<boolean> = this.store.select(openCloseSidebarRightSelector);
  readonly hasPipedriveIntegration$: Observable<boolean> = this.store.select(hasPipedriveIntegrationSelector);
  readonly settingsModules$: Observable<Array<SettingsModule>> = this.store.select(settingsModulesSelector);

  pager: Pager<MessageScheduled> = new Pager<MessageScheduled>({ perPage: 7 });
  attendance: Attendance;

  public messages: Array<Message> = new Array<Message>();
  public pagerMessage: Pager<Message> = new Pager<Message>({ perPage: 10 });

  sidebarRight: boolean = false;
  isInputFocused: boolean = false;
  showContainerEmoji: boolean = false;
  integration: boolean = false;

  textEmoji!: any;
  translator!: Traduct;
  dialogRef: MatDialogRef<any>;
  pagerParamsState: PagerParamsState;

  isRecording!: boolean;
  stream!: MediaStream;
  recorder!: MediaRecorder;
  audioChunks: Blob[] = [];
  isCancel!: boolean;
  audioUrl!: string | null;
  isPreview: boolean = false;
  audioFile!: File | undefined;
  recordingTime: number = 0;
  intervalId!: any;
  isLoading: boolean = false;

  isWaitingMessageScheduled: boolean = false;
  lastMessageDate: Date;
  dateFormatted!: string;

  selectedIndexTab: number = 0;
  showEmpty: boolean = false;

  statusPaused: AttendanceStatusEnum = AttendanceStatusEnum.PAUSED;
  statusInProgress: AttendanceStatusEnum = AttendanceStatusEnum.IN_PROGRESS;

  isMessageReplyed: boolean = false;
  messageReplyed!: Message;

  private clearTimeout
  private clickOutsideListener
  isMessageEdit: boolean = false;
  messageEdit: Message;

  activeIAModule: boolean = false;
  activeWavoipModule: boolean = false;

  constructor(
    injector: Injector,
    private loadingMessage: LoadMessageService,
    private modalResponse: MatDialog,
    private store: Store<AppState>,
    private tabService: TabService,
    private audioService: AudioService,
    private modalUploadPreview: MatDialog,
    private modalAlert: MatDialog,
    public dialog: MatDialog,
    public messageService: MessageService,
    public userService: UserService,
    public contactService: ContactService,
    public channelService: ChannelService,
    public attendanceService: AttendanceService,
    public socketService: SocketService,
    public firebaseService: FirebaseService,
    public responseService: ResponseService,
    private modalScheduleMessage: MatDialog,
    private modalScheduleMessageList: MatDialog,
    private modalSendContact: MatDialog,
    private modalSendLocation: MatDialog,
    private renderer: Renderer2,
    private modalTagAttendance: MatDialog,
    private bottomSheet: MatBottomSheet,
    private modalCallPhone: MatDialog,
  ) {
    super(injector);
    this.translator = UtilHelper.getEmojiTranslator();
  }

  @HostListener('document:paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const items = event.clipboardData?.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf('image') !== -1) {
        const file = item.getAsFile();
        if (file) {
          this.onSelectMedia('image', file);
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  }

  ngOnInit(): void {
    this.setupForm();
    this.initComponent();
  }

  ngAfterViewInit(): void {
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initComponent() {
    this.attendance$.subscribe(attendance => {
      if (!attendance) return;

      const idCompany: unknown = attendance.company._id ? attendance.company._id : attendance.company;
      if (idCompany !== super.getIDCurrentCompany()) {
        this.alertService.info(`Não foi possível localizar esse atendimento na empresa: ${super.getNameCompanyCurrentUser()}`);
        return;
      }

      sessionStorage.removeItem('preview_audio');
      this.audioUrl = null;
      this.isLoading = false;
      if (this.attendance?._id !== attendance._id) {
        this.formGroup.reset('');
        this.isMessageReplyed = false;
        this.isMessageEdit = false;
      }
      this.attendance = attendance;
      this.messages = [];
      this.pagerMessage = new Pager<Message>();
      this.destroy$.next(); // clear all subscriptions of last attendances messages

      this.store.dispatch(getMessages({
        page: this.pagerMessage.page,
        perPage: this.pagerMessage.perPage,
        idAttendance: attendance._id, idCompany: super.getIDCurrentCompany()
      }));
      this.configSocket();

      // Get settings modules of channel to check if IA and Wavoip are active 
      this.settingsModules$.subscribe((settingsModules: Array<SettingsModule>) => {
        const settingsModuleOfChannel: SettingsModule = settingsModules.find((settingsModule: SettingsModule) => settingsModule.channel._id === this.attendance.channel._id);
        if (!!settingsModuleOfChannel) {
          this.activeIAModule = settingsModuleOfChannel.activeIAModule;
          this.activeWavoipModule = settingsModuleOfChannel.activeWavoipModule;
        }
      });

    });

    this.messages$.subscribe((pager: Pager<Message>) => {
      this.pagerMessage.page = pager.page;
      this.pagerMessage.perPage = pager.perPage;
      this.pagerMessage.previousPage = pager.previousPage;
      this.pagerMessage.total = pager.total;
      this.pagerMessage.list = pager.page === 1 ? pager.list : [...this.pagerMessage.list, ...pager.list];
      if (this.pagerMessage.total > 0) {
        this.pagerMessage.list = [...this.pagerMessage.list].sort((a, b) => {
          const d1 = new Date(a.createdAt).getTime();
          const d2 = new Date(b.createdAt).getTime();
          return (d1 > d2) ? 1 : -1;
        });
      }

      this.messages = this.pagerMessage.list;
      this.loadingMessage.setLoadingMessage(false);
    });

    this.sidebarRight$.subscribe(sidebarRight => {
      this.sidebarRight = sidebarRight;
    });

    this.pagerParams$.subscribe(pager => {
      this.pagerParamsState = pager;
    });

  }

  // Record audio
  private startRecording(idAttendance: string): void {
    if (this.isRecording) return;

    this.recordingTime = 0;
    this.intervalId = setInterval(() => {
      this.recordingTime++;
    }, 1000);

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        this.isRecording = true;
        this.isCancel = false;
        this.stream = stream;
        this.recorder = new MediaRecorder(this.stream);
        this.audioChunks = [];

        this.recorder.ondataavailable = (event) => {
          if (!this.isCancel) {
            this.audioChunks.push(event.data);
          }
        };

        this.recorder.onstop = async () => {
          if (!this.isCancel) {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
            const filename = `audio_${idAttendance}.mp3`;
            const newBlob = await this.audioService.convertWebMToMp3(audioBlob);
            this.audioFile = new File([newBlob], filename, { type: 'audio/mpeg' });

            await this.uploadAudioToFirebase(idAttendance, audioBlob);
          } else {
            this.audioChunks = [];
          }
        };

        this.recorder.start();
      }).catch((err) => this.alertService.error(err));
  }

  // Upload audio to firebase
  private async uploadAudioToFirebase(idAttendance: string, audioBlob: Blob): Promise<void> {
    if (!this.audioFile) {
      this.alertService.error('Erro ao tentar fazer o upload do áudio.');
      return;
    }

    try {
      this.isLoading = true;

      const dateMilisecond = new Date().getTime();
      const filename: string = `${idAttendance}_audio_${dateMilisecond.toString()}.mp3`;

      const snapshot = await this.firebaseService.uploadFile(this.audioFile, filename, UploadTypeEnum.MESSAGE);

      this.audioUrl = snapshot.url;

      this.isLoading = false;
    } catch (e) {
      this.alertService.error('Ops! Não foi possível realizar o upload do arquivo.');
      this.isLoading = false;
    }
  }

  // Stop recording audio
  stopRecording(): void {
    if (this.isRecording) {
      clearInterval(this.intervalId);
      this.recorder.stop();
      this.isRecording = false;
      this.stream?.getTracks().forEach(track => track.stop());
    }
  }

  // Cancel recording audio
  cancelRecording(): void {
    if (this.recorder && this.isRecording) {
      this.isCancel = true;
      this.recorder.stop();
      this.isRecording = false;
      this.stream?.getTracks().forEach(track => track.stop());
    }
  }

  // Toggle recording audio
  toggleRecording(idAttendance: string): void {
    if (this.isRecording) {
      this.isLoading = true;
      this.stopRecording();
    } else {
      this.isLoading = false;
      this.isPreview = true;
      this.startRecording(idAttendance);
    }
  }

  // Cancel preview audio
  cancelPreview(idAttendance: string): void {
    this.isLoading = false;
    this.audioUrl = null;
    this.audioFile = undefined;

    if (this.audioContainer) this.audioContainer.nativeElement.innerHTML = '';
  }

  // Send audio
  sendAudio(attendance: Attendance): void {
    const maxSize = 16 * 1024 * 1024;

    if (this.audioFile && this.audioFile.size > maxSize) {
      this.alertService.error('Ops! Você gravou um áudio muito grande. Só é permitido 16MB.');
      return;
    }

    this.isLoading = false;
    this.replyMedia(attendance, MessageTypeEnum.audio, this.audioUrl, this.audioFile.type, this.audioFile.name + '_' + new Date().getTime().toString());
    this.audioUrl = null;

    if (this.audioContainer) this.audioContainer.nativeElement.innerHTML = '';
  }

  getFormattedTime(): string {
    const minutes: string = Math.floor(this.recordingTime / 60).toString().padStart(2, '0');
    const seconds: string = (this.recordingTime % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  }

  private refreshStoreAttendancePage() {
    if (!environment.socketActived) {
      this.store.dispatch(getMessages({ page: this.pagerMessage.page, perPage: this.pagerMessage.perPage, idAttendance: this.attendance._id, idCompany: super.getIDCurrentCompany() }));
    }
  }

  private setupForm() {
    this.formGroup = this.formBuilder.group({
      message: [null, Validators.required],
    });
  }

  sendText() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const text = this.formGroup.get('message').value;
    this.replyText(this.attendance, text);

    this.formGroup.reset('');
  }

  sendMedia(mediaType: 'image' | 'file' | 'location' | 'audio' | 'video' | 'document') {
    switch (mediaType) {
      case 'image': this.inputImagefile.nativeElement.click(); break;
      case 'video': this.inputVideofile.nativeElement.click(); break;
      case 'document': this.inputDocfile.nativeElement.click(); break;
      case 'audio': this.inputAudiofile.nativeElement.click(); break;
    }
  }

  selectTemplate() {
    const dialogRef = this.dialog.open(ChatSelectTemplateComponent, {
      width: '1000px',
      data: this.attendance,
      height: '587px'
    });

    dialogRef.afterClosed().subscribe((metadataMessage: MetadataMessage) => {
      if (metadataMessage) {
        const content = metadataMessage?.headerParams?.[0] ?? metadataMessage.previewText
        this.replyTextTemplate(this.attendance, content, metadataMessage);
      }
    });
  }

  onSelectMedia(type: string, fileOrEvent: File | any): void {
    if (!this.attendance?.user) {
      this.alertService.success("Nenhum atendente foi definido para o atendimento.");
      return;
    }

    const file: File = fileOrEvent instanceof File ? fileOrEvent : (fileOrEvent.target as HTMLInputElement)?.files?.[0];
    const fileTypeLimit = UtilHelper.getFileTypeLimit(file.type);

    if (fileTypeLimit && file.size > fileTypeLimit.maxSize) { // Check type and max size of file
      this.openModalAlert(fileTypeLimit.alertTitle, fileTypeLimit.alertMessage, this.getInputElement(file.type));
      return;
    }

    if (file) {
      const dialogRefUpload = this.modalUploadPreview.open(PreviewMediaComponent, {
        width: '600px',
        data: { type: type, url: '', filename: file.name, isTableResponseApp: false },
      });

      const reader = new FileReader();
      reader.onload = async (fileEvent) => {
        try {
          this.loading = true;

          const dateMilisecond = new Date().getTime();
          const filename: string = file.name + '_' + dateMilisecond.toString();

          this.firebaseService.uploadFile(file, filename, UploadTypeEnum.MESSAGE).then((snapshot: { url: string }) => {
            dialogRefUpload.componentInstance.showPreview(snapshot.url);
            this.loading = false;

            dialogRefUpload.afterClosed().subscribe((result) => {
              if (result) {
                result.message ? this.replyMedia(this.attendance, <MessageTypeEnum>type, result.midia, file.type, result.message) : this.replyMedia(this.attendance, <MessageTypeEnum>type, result.midia, file.type);
              }
              this.resetInputFiles();
            });
          });
        } catch (e) {
          console.error(e);
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      this.alertService.error('Ops! Não foi possível realizar o upload do arquivo.');
      return;
    }
  }

  replyTextTemplate(attendance: Attendance, content: string, metadataMessage: MetadataMessage): void {
    const message = MessageHelper.createMessageFromAttendance(attendance, MessageTypeEnum.template, content, null, super.getIDCurrentUser());
    message.metadata = metadataMessage;
    this.createPostMessage(message);
  }

  replyText(attendance: Attendance, text: string): void {
    this.isMessageReplyed ? this.createPostMessage(MessageHelper.createMessageFromAttendance(attendance, MessageTypeEnum.text, text, null, super.getIDCurrentUser(), null, null, this.isMessageReplyed, this.messageReplyed)) :
      this.isMessageEdit ? this.replyEditMessage(this.messageEdit) :
        this.createPostMessage(MessageHelper.createMessageFromAttendance(attendance, MessageTypeEnum.text, text, null, super.getIDCurrentUser()));
  }

  replyMedia(attendance: Attendance, type: MessageTypeEnum, content: string, contentType: string, caption?: string): void {
    if (this.attendance._id !== attendance._id) {
      this.alertService.warning('O áudio foi cancelado devido a troca de atendimentos.');
      sessionStorage.removeItem(`preview_audio_${attendance._id}`);
      if (this.audioContainer) this.audioContainer.nativeElement.innerHTML = '';
      return;
    }

    const message = caption && (type === 'image' || type === 'document' || type === 'video')
      ? MessageHelper.createMessageFromAttendance(attendance, type, content, contentType, super.getIDCurrentUser(), caption, null, this.isMessageReplyed ? this.isMessageReplyed : false, !!this.messageReplyed ? this.messageReplyed : null)
      : MessageHelper.createMessageFromAttendance(attendance, type, content, contentType, super.getIDCurrentUser(), null, null, this.isMessageReplyed ? this.isMessageReplyed : false, !!this.messageReplyed ? this.messageReplyed : null);

    this.createPostMessage(message);
  }

  replyContact(attendance: Attendance, type: MessageTypeEnum.contacts, vcardContact: Array<Contact>): void {
    this.createPostMessage(MessageHelper.createMessageFromAttendance(attendance, type, `Envio de mensagem com contato`, null, super.getIDCurrentUser(), null, vcardContact));
  }

  replyLocation(attendance: Attendance, type: MessageTypeEnum.location, place: GooglePlaceType): void {
    this.createPostMessage(MessageHelper.createMessageFromAttendance(attendance, type, `${place.formattedAddress ?? 'Envio de mensagem de localização'}`, null, super.getIDCurrentUser(), null, null, null, null, place));
  }

  replyEditMessage(message: Message): void {
    let newContent = this.formGroup.get('message')?.value;
    if (super.getSignatureConversation()) {
      newContent = `> _*${this.getNameCurrentUser()}*_ \n\n ${newContent}`;
    }

    this.messageService.editMessage(message._id, newContent).subscribe({
      next: () => {
        this.alertService.success('Messagem editada com sucesso!');
        this.isMessageEdit = false;
        this.store.dispatch(getMessages({ page: this.pagerMessage.page, perPage: this.pagerMessage.perPage, idAttendance: this.attendance._id, idCompany: super.getIDCurrentCompany() }));
      },
      error: () => {
        this.isMessageEdit = false;
      },
    });
  }

  private createPostMessage(message: Message) {
    if (message.type === MessageTypeEnum.text && super.getSignatureConversation()) {
      message.content = `> _*${this.getNameCurrentUser()}*_ \n\n ${message.content}`;
    }

    let assignUserSubscription = of({});
    const shouldAssignUser = !this.attendance.user || this.attendance.status !== AttendanceStatusEnum.IN_PROGRESS;
    if (shouldAssignUser) {
      assignUserSubscription = this.attendanceService.assignUser(this.attendance._id, super.getIDCurrentUser());
    }
    assignUserSubscription.pipe(
      catchError((error) => {
        return throwError(() => error); // Repropaga erros que não são BusinessException
      })
    ).subscribe({
      next: (assignUserResult) => {
        // Somente cria a mensagem se não houve BusinessException
        forkJoin({
          messageServiceCreateSubscription: this.messageService.create(message)
        }).subscribe(({ messageServiceCreateSubscription }) => {
          if (shouldAssignUser) {
            this.attendance = <Attendance>assignUserResult;
            this.store.dispatch(setAttendance({ attendance: this.attendance }));
            this.alertService.success(`${super.getNameCurrentUser()} assumiu o atendimento.`);
          }
          this.tabService.selectedTabIndex(2);
          this.isMessageReplyed = false;
        });
      },
      error: (error) => {
        console.error('Erro ao atribuir usuário:', error);
      }
    });
  }

  toggleSidebar() {
    this.sidebarRight = !this.sidebarRight;
    this.store.dispatch(getAttendance({ idAttendance: this.attendance._id }))
    this.store.dispatch(openCloseSidebarRight({ sidebarRight: this.sidebarRight }));
    this.store.dispatch(getNotes({ idAttendance: this.attendance._id, idContact: this.attendance.contact._id }));
  }

  focusInput(): void {
    this.isInputFocused = true;
  }

  blurInput(): void {
    this.isInputFocused = false;
  }

  enterEventPress(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.sendText();
  }

  toggleContainerEmoji(): void {
    clearTimeout(this.clearTimeout)

    this.showContainerEmoji = !this.showContainerEmoji;

    this.clearTimeout = setTimeout(() => {
      this.addClickOutsideListener();
    })
  }

  addClickOutsideListener() {
    this.clickOutsideListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      if (this.showContainerEmoji && this.emojiContainer) {
        const modalElement = this.emojiContainer.nativeElement as HTMLElement;
        const clickedInside = modalElement?.contains(event.target as Node);
        if (!clickedInside) {
          this.closedContainerEmoji();
        }
      }
    });
  }

  addEmoji(event: any): void {
    const messageFg = this.formGroup.get('message');
    const currentText = messageFg.value || '';

    messageFg.setValue(currentText + event.emoji.native);
  }

  closedContainerEmoji(): void {
    this.showContainerEmoji = false;
    this.clickOutsideListener()
    this.clickOutsideListener = null
  }

  openAssignedUserTransferModal() {
    const dialogRef = this.dialog.open(AssignedUserComponent, {
      width: '600px',
      data: this.attendance
    });
    dialogRef.afterClosed().subscribe((attendance: Attendance) => {
      if (!!attendance) {
        this.store.dispatch(setAttendance({ attendance }));
        this.tabService.selectedTabIndex(1);
      }
    });
  }

  closeAttendance() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: 'Tem certeza que deseja encerrar o atendimento?',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.attendanceService.closeAttendance(this.attendance._id).subscribe({
          next: (attendance: Attendance) => {
            this.store.dispatch(setAttendance({ attendance }));
            this.alertService.success('Atendimento encerrado com sucesso!');
            this.store.dispatch(getAttendances({
              page: this.pagerParamsState.page,
              perPage: this.pagerParamsState.perPage,
              search: this.pagerParamsState.search,
              params: this.pagerParamsState.params,
            }));
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      };
    });
  }

  openModalResponse(): void {
    const refBottom = this.modalResponse.open(ModalResponseFastComponent, { width: '70rem' });

    refBottom.afterClosed().subscribe(async (result) => {
      if (result !== null && result !== undefined) {
        if (result.vcardContact) {
          this.replyContact(this.attendance, MessageTypeEnum.contacts, result.vcardContact);
        } else if (result.place) {
          this.replyLocation(this.attendance, MessageTypeEnum.location, result.place);
        } else {
          let messageFg = this.formGroup.get('message');

          if (result.midia || result.midia === 'N/A') {
            const mediaType = this.helperExtension(result.midia);
            const isCaptionSend = mediaType === 'image' || mediaType === 'video' || mediaType === 'document';

            if (isCaptionSend) {
              const newContent = UtilHelper.replaceIdentifiers(result.message, this.attendance, super.getNameCompanyCurrentUser(), super.getNameCurrentUser());
              this.replyMedia(this.attendance, mediaType, result.midia, result.response.contentType, newContent);
            } else {
              const newContent = UtilHelper.replaceIdentifiers(result.message, this.attendance, super.getNameCompanyCurrentUser(), super.getNameCurrentUser());

              this.replyMedia(this.attendance, mediaType, result.midia, result.response.contentType);
              setTimeout(() => {
                this.replyText(this.attendance, newContent);
              }, 700);
            }
          } else {
            const newContent = UtilHelper.replaceIdentifiers(result.message, this.attendance, super.getNameCompanyCurrentUser(), super.getNameCurrentUser());
            messageFg.setValue(newContent);
          }
        }
      };
    });
  };

  formatExpireIn(date: Date): { text: string, isExpired: boolean } {
    return UtilHelper.formatExpireIn(date);
  }

  isExpiredisConversation(): boolean {
    if (this.attendance?.channel?.type === ChannelTypeEnum.CLOUD_API) {
      const isConversation = this.attendance?.inConversation;
      if (isConversation) {
        const expiredAtDate = new Date(this.attendance.expiredAt);
        const currentDate = new Date();
        const isExpired = expiredAtDate < currentDate;
        return isExpired ? !isConversation : isConversation;
      }
      return false;
    } else {
      return true;
    }
  }

  private helperExtension(midia: string): MessageTypeEnum {
    const decodedUrl = decodeURIComponent(midia);

    const fileNameWithQuery = decodedUrl.split('/').pop() || '';
    const fileName = fileNameWithQuery.split('?')[0];

    const extension = fileName.split('.').pop()?.toLowerCase();

    const regex = /_(\d+)/;
    const nameWithoutPart = extension.replace(regex, '');

    switch (nameWithoutPart) {
      case 'mp4':
        return MessageTypeEnum.video;
      case 'pdf':
        return MessageTypeEnum.document;
      case 'mp3':
        return MessageTypeEnum.audio;
      default:
        return MessageTypeEnum.image;
    }
  }

  formatDuration(duration: number): string {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  updateStatusAttendance(status: AttendanceStatusEnum): void {
    this.attendanceService.updateStatus(this.attendance._id, status).subscribe({
      next: (attendance: Attendance) => {
        this.attendance = attendance;
        if (attendance.status === this.statusPaused) {
          this.alertService.success('Atendimento pausado com sucesso!');
          this.tabService.selectedTabIndex(3);
        } else {
          this.alertService.success('Atendimento em continuidade!');
          this.tabService.selectedTabIndex(2);
        }
      },
      complete: () => {
        this.refreshStoreAttendancePage();
      },
    });
  }

  private resetInputFiles(): void {
    this.inputImagefile.nativeElement.value = '';
    this.inputVideofile.nativeElement.value = '';
    this.inputAudiofile.nativeElement.value = '';
    this.inputDocfile.nativeElement.value = '';
  }

  private openModalAlert(title: string, message: string, inputElementFile: ElementRef<HTMLInputElement>): void {
    const dialogRefAlert = this.modalAlert.open(AlertModalComponent, {
      width: '600px',
      maxHeight: '300px',
      data: { title: title, message: message },
    });
    dialogRefAlert.afterClosed().subscribe(() => inputElementFile.nativeElement.value = '');
  }

  private getInputElement(fileType: string): ElementRef {
    if (fileType.startsWith('image/')) {
      return this.inputImagefile;
    } else if (fileType.startsWith('video/')) {
      return this.inputVideofile;
    } else if (fileType.startsWith('audio/')) {
      return this.inputAudiofile;
    } else {
      return this.inputDocfile;
    }
  }

  tmpAttendanceFromSocket: Attendance;
  tmpMessageFromSocket: Message;

  updateChatFromSocket() {
    if (this.attendance?._id === this.tmpAttendanceFromSocket._id) {
      const isAttendance = super.isAttendant();
      const isUserLogged = super.getIDCurrentUser();
      if (isAttendance && this.tmpAttendanceFromSocket.user?._id !== isUserLogged) {
        this.attendance = null;
        this.alertService.info('Este atendimento foi transferido para outro departmento e/ou atendente.');
      } else {
        this.attendance = this.tmpAttendanceFromSocket;
      }
    }
  }

  updateMessagesFromSocket() {
    if (this.tmpMessageFromSocket.attendance._id === this.attendance._id) {
      const checkIfMessageIdExists = this.messages.find((message) => message._id === this.tmpMessageFromSocket._id);
      if (!checkIfMessageIdExists) {
        if (this.tmpMessageFromSocket.type === MessageTypeEnum.reaction) {
          this.store.dispatch(getMessages({ page: 1, perPage: this.pagerMessage.perPage, idAttendance: this.attendance._id, idCompany: super.getIDCurrentCompany() }));
        } else {
          this.messages = [...this.messages, this.tmpMessageFromSocket];
        }
      }
    }
  }

  private configSocket(): void {
    if (!!this.attendance) {
      const code = super.getCodeCompanyCurrentUser();
      const phone = this.attendance.contact.phone;
      const idAttendance = this.attendance._id;

      const snameAttendance = `event_attendance_${code}_${this.attendance._id}`;
      const snameMessage = `event_message_${code}_${phone}_${idAttendance}`;

      this.socketService.listen(snameAttendance)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (attendance: Attendance) => {
            this.tmpAttendanceFromSocket = attendance;
            this.btnUpdateChatFromSocket.nativeElement.click();
          }
        );

      this.socketService.listen(snameMessage)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (message: Message) => {
            this.tmpMessageFromSocket = message;
            this.btnUpdateMessagesFromSocket.nativeElement.click();
          }
        );
    }
  }

  openModalScheduled(attendance: Attendance): void {
    this.modalScheduleMessage.open(ChatMessageScheduleComponent, {
      width: '750px',
      data: { attendance: attendance }
    });
  }

  openModalScheduledTemplate(attendance: Attendance): void {
    this.modalScheduleMessage.open(ChatTemplateScheduleComponent, {
      width: '950px',
      data: { attendance: attendance }
    });
  }

  openModalScheduledList(attendance: Attendance): void {
    this.modalScheduleMessageList.open(ChatMessageScheduleListComponent, {
      width: '700px',
      maxHeight: '700px',
      data: attendance
    });
  }

  showDetailsAttendanceHitory(item: Attendance) {
    this.dialog.open(AttendanceHistoryComponent, {
      width: '90vw',
      height: '95vh',
      data: <AttendanceHistoryData>{
        idContact: item.contact._id,
        idAttendance: item._id,
      }
    });
  }

  sendContact(): void {
    const dialogRef = this.modalSendContact.open(ContactListModalComponent, {
      maxHeight: '1200px',
      minHeight: '900px',
      width: '1200px'
    });
    dialogRef.afterClosed().subscribe((contact: Contact) => {
      if (!!contact) {
        this.replyContact(this.attendance, MessageTypeEnum.contacts, [contact]);
      }
    });
  }

  getMessageReply(event: Message): void {
    this.isMessageReplyed = true;
    this.isMessageEdit = false;
    this.messageReplyed = event;
  };

  cancelReply(): void {
    this.isMessageReplyed = false;
  }

  sendLocation(): void {
    const dialogRef = this.modalSendLocation.open(GoogleMapsComponent, {
      maxHeight: '710px',
      minHeight: '710px',
      width: '790px'
    });
    dialogRef.afterClosed().subscribe((place: GooglePlaceType) => {
      if (!!place) {
        this.replyLocation(this.attendance, MessageTypeEnum.location, place);
      }
    });
  }

  getMessageEdit(event: Message): void {
    const idUser: unknown = event?.user;

    const createdTime = new Date(event.createdAt).getTime();
    const time = new Date().getTime() - createdTime;
    if (time > 15 * 60 * 1000) {
      this.alertService.info('Você não pode editar mensagens depois de 15 minutos.');
      return;
    }

    if (super.isAttendant() && idUser !== super.getCurrentUserUser()._id) {
      this.alertService.warning('Você não pode editar uma mensagem que não é sua.');
      return;
    }

    this.isMessageEdit = true;
    this.isMessageReplyed = false;
    this.messageEdit = event;
  }

  cancelEdit(): void {
    this.isMessageEdit = false;
  }

  showTagsAttendance(): void {
    this.store.dispatch(getTags({ idCompany: super.getIDCurrentCompany() }));
    this.modalTagAttendance.open(TagAttendanceComponent, {
      width: '700px',
      maxHeight: '700px',
      data: this.attendance,
    });
  }

  loadPage(): void {
    if (this.pagerMessage.list.length < this.pagerMessage.total) {
      this.store.dispatch(getMessages({
        page: this.pagerMessage.page + 1,
        perPage: this.pagerMessage.perPage,
        idAttendance: this.attendance._id, idCompany: super.getIDCurrentCompany()
      }));
    }
  }

  blockOrUnblock(contact: Contact, channel: Channel): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: contact?.blockedChannels?.includes(channel._id) ? 'Tem certeza que deseja desbloquear esse contato?' : 'Tem certeza que deseja bloquear esse contato?',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        if (!!contact.blockedChannels) {
          const contactIsBlockedInChannel = contact?.blockedChannels?.includes(channel._id);

          if (contactIsBlockedInChannel) {
            this.contactService.unblockContact(contact, channel).subscribe({
              next: () => {
                this.alertService.success('Contato desbloqueado com sucesso!');
                this.store.dispatch(getAttendance({ idAttendance: this.attendance._id }));
                this.store.dispatch(getAttendances({
                  page: this.pagerParamsState.page,
                  perPage: this.pagerParamsState.perPage,
                  search: this.pagerParamsState.search,
                  params: this.pagerParamsState.params,
                }));
              },
              error: (err) => this.alertService.error(err),
            });
          } else {
            this.contactService.blockContact(contact, channel).subscribe({
              next: () => {
                this.alertService.success('Contato bloqueado com sucesso!');
                this.store.dispatch(getAttendance({ idAttendance: this.attendance._id }));
                this.store.dispatch(getAttendances({
                  page: this.pagerParamsState.page,
                  perPage: this.pagerParamsState.perPage,
                  search: this.pagerParamsState.search,
                  params: this.pagerParamsState.params,
                }));
              },
              error: (err) => this.alertService.error(err),
            });
          }
        }
      }
    });
  }

  contactIsBlocked(contact: Contact, channel: Channel): boolean {
    return contact?.blockedChannels?.includes(channel._id);
  }

  openDialogCrm(attendance: Attendance): void {
    this.dialog.open(CrmIntegrationsComponent, {
      width: '700px',
      minHeight: '400px',
      data: attendance
    });
  }

  handleKeydown(event: KeyboardEvent): void {
    const messageControl = this.formGroup.get('message');

    if (event.key === '/' && !messageControl?.value) {
      event.preventDefault();
      this.openModalResponseBottom();
    }
  }

  openModalResponseBottom(): void {
    const bottomRef = this.bottomSheet.open(BottomSheetResponseFastComponent, {
      panelClass: 'custom-bottom-sheet',
    });

    bottomRef.afterDismissed().subscribe((result) => {
      if (result !== null && result !== undefined) {
        if (result.vcardContact) {
          this.replyContact(this.attendance, MessageTypeEnum.contacts, result.vcardContact);
        } else if (result.place) {
          this.replyLocation(this.attendance, MessageTypeEnum.location, result.place);
        } else {
          const messageFg = this.formGroup.get('message');

          if (result.midia || result.midia === 'N/A') {
            const mediaType = this.helperExtension(result.midia);
            const isCaptionSend = mediaType === 'image' || mediaType === 'video' || mediaType === 'document';

            if (isCaptionSend) {
              const newContent = UtilHelper.replaceIdentifiers(result.message, this.attendance, super.getNameCompanyCurrentUser(), super.getNameCurrentUser());
              this.replyMedia(this.attendance, mediaType, result.midia, result.response.contentType, newContent);
            } else {
              this.replyMedia(this.attendance, mediaType, result.midia, result.response.contentType);
              setTimeout(() => {
                const newContent = UtilHelper.replaceIdentifiers(result.message, this.attendance, super.getNameCompanyCurrentUser(), super.getNameCurrentUser());
                this.replyText(this.attendance, newContent);
              }, 700);
            }
          } else {
            const newContent = UtilHelper.replaceIdentifiers(result.message, this.attendance, super.getNameCompanyCurrentUser(), super.getNameCurrentUser());
            messageFg.setValue(newContent);
          }
        }
      };
    });
  }

  openCall(): void {
    const ref = this.modalCallPhone.open(CallPhoneComponent, {
      data: this.attendance.contact,
    });

    ref.afterClosed().subscribe(result => {
    });
  }

  isAIActive(): boolean {
    return this.attendance?.metadata?.integrations?.ai?.actived === true;
  }

  activeUnactiveAI() {
    if (this.isAIActive()) {
      this.attendanceService.activeUnactiveAI(this.attendance._id, false, null, null).subscribe({
        next: () => {
          this.store.dispatch(getAttendance({ idAttendance: this.attendance._id }));
          this.alertService.success('IA desativada com sucesso!');
        },
        error: (err) => this.alertService.error(err.error.message)
      });
      return;
    }

    const dialogRef = this.dialog.open(AiAssistantActivatorSelectorComponent, {
      width: '600px',
      data: this.attendance,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const updatedAi = {
          name: result.assistant?.name || null,
          actived: result.activeIAModule,
          assistant: result.assistant,
          integration: result.integration,
          openai: {
            threadId: this.attendance?.metadata?.integrations.ai?.openai?.threadId
          }
        };

        const updatedIntegrations = { ...this.attendance?.metadata?.integrations, ai: updatedAi };
        const updatedMetadata = { ...this.attendance?.metadata, integrations: updatedIntegrations };
        this.attendance = { ...this.attendance, metadata: updatedMetadata };

        this.attendanceService.activeUnactiveAI(
          this.attendance._id,
          result.activeIAModule,
          result.integration,
          result.assistant
        ).subscribe({
          next: () => {
            this.store.dispatch(getAttendance({ idAttendance: this.attendance._id }));
            this.alertService.success('IA ativada com sucesso!');
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      }
    });
  }


}
