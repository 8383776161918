import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { BodyComponentObject, TemplateMessageObject, TemplateMessageObjectPager } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Channel, ChannelService, Pager } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'wa-template',
  templateUrl: './wa-template.component.html',
  styleUrls: ['./wa-template.component.scss']
})
export class WaTemplateComponent extends AbstractComponent {
  selectedTemplateId: string | null = null;
  pager: Pager<TemplateMessageObject> = new Pager<TemplateMessageObject>({ perPage: 4 });

  @Input() set channel(channel: Channel) {
    this.selectedChannel = channel;
    this.getList();
  }

  @Output() onSelectTemplate = new EventEmitter<TemplateMessageObject>();

  templates: Array<TemplateMessageObject> = new Array<TemplateMessageObject>()
  selectedChannel: Channel

  constructor(injector: Injector, private channelService: ChannelService) {
    super(injector);
  }

  async getList() {
    if (!this.selectedChannel) return;

    this.channelService.getTemplatesByChannel(this.selectedChannel._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (templateMessageObjectPager: TemplateMessageObjectPager) => {
          this.templates = templateMessageObjectPager.data;
        }
      });

  }

  showTemplateDetails(template: TemplateMessageObject) {
    this.onSelectTemplate.emit(template)
  }

  getBodyText(template: TemplateMessageObject): string {
    const bodyComponent = <BodyComponentObject>template?.components.find(c => c.type === 'BODY');
    return bodyComponent.text;
  }

}
