import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Attendance, Attendances, AttendanceService, CacheService, Company, CompanyService, CountState, Integration, IntegrationService, IntegrationTypeEnum, Message, MessageService, Note, NoteService, Pager, SettingsModule, SettingsModuleService, SimpleListsFilter, Tag, TagService, User, UserService } from "lib-trend-core";
import { of, Subject } from "rxjs";
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap, takeUntil } from "rxjs/operators";
import * as AttendanceActions from "./actions";
import { PagerParamsState } from './app.state';

export enum CacheControlTypeEnum {
  MANAGED = "MANAGED",
  CACHED = "CACHED"
}

@Injectable()
export class Effects {

  private destroy$ = new Subject<void>();

  private cacheControl: CacheControlTypeEnum = CacheControlTypeEnum.MANAGED;

  constructor(
    private store: Store,
    private actions$: Actions,
    private cacheService: CacheService,
    private attendanceService: AttendanceService,
    private messageService: MessageService,
    private companyService: CompanyService,
    private userService: UserService,
    private noteService: NoteService,
    private tagService: TagService,
    private integrationService: IntegrationService,
    private settingsModuleService: SettingsModuleService,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(AttendanceActions.clearFullState({ clear: true }));
  }

  ngOnDestroy(): void {
    this.destroyEffects();
  }

  destroyEffects(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkCacheControl(cached: boolean): boolean {
    return (!!cached && cached === true && this.cacheControl === CacheControlTypeEnum.CACHED);
  }

  loadInitCompanyEffect$ = createEffect(() => {
    let userSelector: User = undefined;
    return this.actions$.pipe(
      ofType(AttendanceActions.getLoadInit),
      exhaustMap((action) => {
        let serviceUserCaller = this.userService.getById(action.idUser);
        if (this.checkCacheControl(action.cached)) {
          serviceUserCaller = this.cacheService.getUserById(action.idUser);
        }
        return serviceUserCaller
          .pipe(
            takeUntil(this.destroy$),
            mergeMap((user: User) => {
              userSelector = user;
              let serviceCompanyCaller = this.companyService.getById(action.idCompany);
              if (this.checkCacheControl(action.cached)) {
                serviceCompanyCaller = this.cacheService.getCompanyById(action.idCompany);
              }
              return serviceCompanyCaller;
            }),
            map((company: Company) => {
              return AttendanceActions.setLoadInit({ user: userSelector, company: company });
            }),
            catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
          );
      })

    );
  });

  countsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getCounts),
      switchMap((action) =>
        this.attendanceService.getCountAttendancesByStatus(action.search, action.params).pipe(
          takeUntil(this.destroy$),
          map((counts: Array<CountState>) => {
            return AttendanceActions.setCounts({ counts });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      )
    );
  });

  attedancesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getAttendances),
      switchMap((action) => {
        return this.attendanceService.getAll(action.page, action.perPage, action.search, action.params).pipe(
          takeUntil(this.destroy$),
          map((pager: Pager<Attendance>) => {
            return AttendanceActions.setAttendances({ attendances: pager });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      })
    );
  });

  attedanceEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getAttendance),
      exhaustMap((action) => {
        let serviceCaller = this.attendanceService.getById(action.idAttendance);
        if (this.checkCacheControl(action.cached)) {
          serviceCaller = this.cacheService.getAttendanceById(action.idAttendance);
        }
        return serviceCaller
          .pipe(
            takeUntil(this.destroy$),
            map((attendance: Attendance) => {
              return AttendanceActions.setAttendance({ attendance });
            }),
            catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
          );
      })
    );
  });

  messagesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getMessages),
      exhaustMap((action) => {
        let serviceCaller = this.messageService.getAll(action.page, action.perPage, null, { attendance: action.idAttendance });
        if (this.checkCacheControl(action.cached)) {
          serviceCaller = this.cacheService.getMessagesByAttendance(action.idCompany, action.idAttendance, action.page, action.perPage, null, { attendance: action.idAttendance });
        }
        return serviceCaller.pipe(
          takeUntil(this.destroy$),
          concatMap((messages: Pager<Message>) => {
            if ((!!messages && messages.list.length > 0 && messages.list[0].attendance.countUnreadMessages > 0)) {
              return this.attendanceService.updateCountUnreadMessages(messages.list[0].attendance._id)
                .pipe(
                  map(() => messages)
                );
            } else {
              return of(messages);
            }
          }),
          map((pager: Pager<Message>) => {
            return AttendanceActions.setMessages({ messages: pager });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      })
    );
  });

  notesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getNotes),
      exhaustMap((action) => {
        let serviceCaller = this.noteService.getByAttendanceAndContact(action.idAttendance, action.idContact);
        if (this.checkCacheControl(action.cached)) {
          serviceCaller = this.cacheService.getNotesByAttandence(action.idAttendance, action.idContact);
        }
        return serviceCaller.pipe(
          takeUntil(this.destroy$),
          map((notes: Array<Note>) => AttendanceActions.setNotes({ notes })),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      })
    );
  });

  pagerParamsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getAttendances),
      switchMap((action) => {
        return of({
          page: action.page,
          perPage: action.perPage,
          search: action.search,
          params: action.params
        }).pipe(
          takeUntil(this.destroy$),
          map((pagerParams: PagerParamsState) => {
            return AttendanceActions.setPagerParams({ pagerParams })
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message })))
        )
      })
    )
  });

  tagsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getTags),
      exhaustMap((action) => {
        let serviceCaller = this.tagService.getList();;
        if (this.checkCacheControl(action.cached)) {
          serviceCaller = this.cacheService.getTagsByCompany(action.idCompany);
        }
        return serviceCaller.pipe(
          takeUntil(this.destroy$),
          map((tags: Array<Tag>) => {
            return AttendanceActions.setTags({ tags });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      })
    );
  });

  integrationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getIntegrations),
      switchMap((action) => {
        // TODO: Need to be implemented the integration service inside cache service
        let serviceCaller = this.integrationService.getListFiltered(null, <IntegrationTypeEnum>(action.ptype));
        if (this.checkCacheControl(action.cached)) {
          serviceCaller = this.integrationService.getListFiltered(null, <IntegrationTypeEnum>(action.ptype));
        }
        return serviceCaller.pipe(
          takeUntil(this.destroy$),
          map((integrations: Integration[]) => {
            const hasPipedriveIntegration = integrations.some(
              (integration) => integration.type === IntegrationTypeEnum.PIPEDRIVE && integration.actived
            );
            return AttendanceActions.setIntegrations({ integrations, hasPipedriveIntegration });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message })))
        )
      })
    );
  });

  simpleListsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getSimpleLists),
      switchMap((action) =>
        this.cacheService.getSimpleListsFiltersByCompany(action.idCompany).pipe(
          takeUntil(this.destroy$),
          map((simpleListsFilter: SimpleListsFilter) => {
            return AttendanceActions.setSimpleLists({
              departaments: simpleListsFilter.departaments,
              channels: simpleListsFilter.channels,
              tags: simpleListsFilter.tags,
              users: simpleListsFilter.users
            });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message })))
        )
      )
    );
  });

  settingsModulesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getSettingsModules),
      switchMap((action) => {
        let serviceCaller = this.settingsModuleService.getByCompany(action.idCompany);
        if (this.checkCacheControl(action.cached)) {
          serviceCaller = this.cacheService.getSettingsModulesByCompany(action.idCompany);
        }
        return serviceCaller.pipe(
          takeUntil(this.destroy$),
          map((settingsModules: Array<SettingsModule>) => {
            return AttendanceActions.setSettingsModules({ settingsModules });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message })))
        )
      })
    );
  });

}
