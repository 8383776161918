
<div [hidden]="step !== 1">
  <div class="w-full">
    <div class="flex justify-between">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
        <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
      </svg>
    </div>

    <h1 class="font-black text-[20px] text-black mt-2.5">Integração com Pipedrive</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Selecione a integração com pipeline que deseja executar as ações
    </h6>

    <div class="grid grid-cols-1  max-w-[100%] mt-[25px]">
      <div class="flex flex-col items-center mt-[20px] rounded-lg">

        <mat-select (selectionChange)="integrationSelection($event)" class="input-field mb-4 p-2"
          placeholder="Selecione sua integração">
          <mat-option *ngFor="let token of apiToken" [value]="token.value">
            {{ token.label }}
          </mat-option>
        </mat-select>

        <div class="flex justify-center  items-center w-full gap-2 mt-[60px]">
          <button
            class="h-[45px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
            (click)="openDialogCrm(attendance)">Cancelar</button>
          <button class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] focus:outline-none w-[50%]
            hover:bg-[#350fca] active:bg-[#2a0a9c] focus:ring-2 focus:ring-blue-500 "
            [disabled]="!selectedIntegrationApiToken" (click)="nextStepLeadsTwo()">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div [hidden]="step !== 2">
  <div class="w-full">

    <div class="flex justify-between">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
        <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
      </svg>
    </div>

    <h1 class="font-black text-[20px] text-black mt-2.5">Ações no Pipedrive</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Selecione ação para realizar com a atual conversa</h6>

    <div class="grid grid-cols-4 gap-6 max-w-[100%] mt-[25px] mb-[25px]">

      <!-- Leads -->
      <button
        class="flex flex-col items-center p-4 bg-white shadow-md hover:shadow-lg rounded-lg hover:bg-gray-50 transition-colors"
        (click)="nextStepLeadsThree()" id="pessoaButton">
        <div class="flex flex-col items-center mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="70" height="70"
            class="cui5-icon cui5-icon--color-undefined undefined">
            <path
              d="M8.75 12c0-1.8 1.46-3.25 3.25-3.25 1.8 0 3.25 1.46 3.25 3.25 0 1.8-1.46 3.25-3.25 3.25-1.8 0-3.25-1.46-3.25-3.25Z">
            </path>
            <path
              d="M11.25 1v1.53c-4.65.36-8.36 4.07-8.72 8.72H1c-.41 0-.75.34-.75.75s.34.75.75.75h1.53c.36 4.65 4.07 8.36 8.72 8.72V23c0 .41.34.75.75.75s.75-.34.75-.75v-1.53c4.65-.36 8.36-4.07 8.72-8.72H23c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-1.53c-.36-4.65-4.07-8.36-8.72-8.72V1c0-.41-.34-.75-.75-.75s-.75.34-.75.75ZM12 7.25c2.62 0 4.75 2.13 4.75 4.75s-2.13 4.75-4.75 4.75S7.25 14.62 7.25 12 9.38 7.25 12 7.25Z">
            </path>
          </svg>
          <span class="text-center font-medium text-lg mt-3">Leads</span>
        </div>
      </button>

      <!-- Pessoa -->
      <button
        class="flex flex-col items-center p-4 bg-white shadow-md hover:shadow-lg rounded-lg hover:bg-gray-50 transition-colors"
        (click)="nextStepLeadsFour()" id="pessoaButton">
        <div class="flex flex-col items-center mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="70" hei ght="70" class="mb-2">
            <path
              d="M6.5 13.5c1.07 0 1.94-.88 1.94-1.96S7.57 9.6 6.5 9.6c-1.07 0-1.94.87-1.94 1.95s.87 1.96 1.94 1.96Zm0 1.48c-2.02 0-3.31 1.34-3.5 3.08-.02.24.18.44.42.44h6.16c.24 0 .44-.2.42-.44-.19-1.74-1.48-3.08-3.5-3.08ZM11.75 12c-.41 0-.75.34-.75.75s.34.75.75.75h5.5c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-5.5ZM11 15.75c0-.41.34-.75.75-.75h3.5c.41 0 .75.34.75.75s-.34.75-.75.75h-3.5c-.41 0-.75-.34-.75-.75Z">
            </path>
            <path
              d="M5.75 2.5C4.23 2.5 3 3.73 3 5.25V6.5c-1.66 0-3 1.26-3 2.81v9.38c0 1.55 1.34 2.81 3 2.81h15c1.66 0 3-1.26 3-2.81V17.5h.25c1.52 0 2.75-1.23 2.75-2.75v-9.5c0-1.52-1.23-2.75-2.75-2.75H5.75ZM21 16V9.31c0-1.55-1.34-2.81-3-2.81H4.5V5.25C4.5 4.56 5.06 4 5.75 4h15.5c.69 0 1.25.56 1.25 1.25v9.5c0 .69-.56 1.25-1.25 1.25H21ZM1.5 18.69V9.3c0-.77.67-1.4 1.5-1.4h15c.83 0 1.5.63 1.5 1.4v9.38c0 .77-.67 1.4-1.5 1.4H3c-.83 0-1.5-.63-1.5-1.4Z">
            </path>
          </svg>
          <span class="text-center font-medium text-lg mt-2">Pessoa</span>
        </div>
      </button>

      <!-- Deals -->
      <button
        class="flex flex-col items-center p-4 bg-white shadow-md hover:shadow-lg rounded-lg hover:bg-gray-50 transition-colors"
        (click)="nextStepLeadsFive()" id="dealsButton">
        <div class="flex flex-col items-center mb-2">
          <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M35 5.83333C18.9 5.83333 5.83331 18.9 5.83331 35C5.83331 51.1 18.9 64.1667 35 64.1667C51.1 64.1667 64.1666 51.1 64.1666 35C64.1666 18.9 51.1 5.83333 35 5.83333ZM32.9 52.5V49.4667C30.9458 49.175 29.4 48.5333 28.2333 47.775C26.775 46.8125 25.9875 45.7042 25.55 45.0333C24.9083 44.0125 25.2583 42.6417 26.25 42.0292C27.2708 41.3875 28.6416 41.6792 29.2833 42.7292C29.4875 43.0792 29.8666 43.6042 30.6541 44.1292C31.3833 44.5958 32.6375 45.1208 34.65 45.2375H35.0291C38.6166 45.1208 40.3958 42.9917 40.3958 41.1833C40.3958 40.6 40.2791 40.25 40.1041 39.9583C39.9291 39.6083 39.6083 39.2292 38.9375 38.7042C37.8875 37.8875 37.1 37.6542 35.3791 37.1L34.5625 36.8083L33.4833 36.5167L33.1916 36.4583C32.6958 36.3417 32.0833 36.1667 31.4416 35.9042C30.7125 35.6125 29.9541 35.2333 29.4 34.9125C29.0208 34.7083 28.6416 34.475 28.2916 34.2125C27.3291 33.4833 26.5416 32.6375 25.9875 31.5875C25.4333 30.5083 25.2291 29.4 25.2291 28.2333C25.2291 23.975 28.6708 20.9708 32.8125 20.0667V17.5C32.8125 16.3042 33.8041 15.3125 35 15.3125C36.1958 15.3125 37.1875 16.3042 37.1875 17.5V19.9792C39.1125 20.2708 40.6 20.8833 41.7666 21.6417C43.225 22.6042 44.0125 23.7417 44.45 24.3833C45.0916 25.4042 44.7416 26.775 43.75 27.4167C42.7291 28.0583 41.3583 27.7083 40.7166 26.7167C40.5125 26.3667 40.1333 25.8125 39.3458 25.3167C38.6166 24.8208 37.3625 24.2958 35.35 24.1792H35C31.4416 24.325 29.575 26.5125 29.575 28.2333C29.575 28.875 29.6916 29.2542 29.8666 29.575C30.0125 29.8667 30.3041 30.2458 30.8583 30.7125L30.975 30.8L31.5583 31.0917C32.025 31.3833 32.55 31.675 33.0166 31.85C33.3958 31.9958 33.775 32.1125 34.2708 32.2292L34.5041 32.2875L35.875 32.6667L36.6625 32.9C38.3541 33.4542 39.9 33.95 41.6208 35.2625C42.525 35.9917 43.3416 36.7792 43.925 37.8C44.5083 38.85 44.7416 39.9875 44.7416 41.1833C44.7416 45.4125 41.3875 48.475 37.2458 49.35V52.5C37.2458 53.6958 36.2541 54.6875 35.0583 54.6875C33.8333 54.6875 32.8708 53.6958 32.8708 52.5H32.9Z"
              fill="black" />
          </svg>
          <span class="text-center font-medium text-lg mt-3">Deals</span>
        </div>
      </button>

      <button
        class="flex flex-col items-center p-4 bg-white shadow-md hover:shadow-lg rounded-lg hover:bg-gray-50 transition-colors"
        (click)="nextStepLeadsSix()" id="pessoaDealsButton">
        <div class="flex items-center mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" class="mb-2">
            <path
              d="M6.5 13.5c1.07 0 1.94-.88 1.94-1.96S7.57 9.6 6.5 9.6c-1.07 0-1.94.87-1.94 1.95s.87 1.96 1.94 1.96Zm0 1.48c-2.02 0-3.31 1.34-3.5 3.08-.02.24.18.44.42.44h6.16c.24 0 .44-.2.42-.44-.19-1.74-1.48-3.08-3.5-3.08ZM11.75 12c-.41 0-.75.34-.75.75s.34.75.75.75h5.5c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-5.5ZM11 15.75c0-.41.34-.75.75-.75h3.5c.41 0 .75.34.75.75s-.34.75-.75.75h-3.5c-.41 0-.75-.34-.75-.75Z">
            </path>
            <path
              d="M5.75 2.5C4.23 2.5 3 3.73 3 5.25V6.5c-1.66 0-3 1.26-3 2.81v9.38c0 1.55 1.34 2.81 3 2.81h15c1.66 0 3-1.26 3-2.81V17.5h.25c1.52 0 2.75-1.23 2.75-2.75v-9.5c0-1.52-1.23-2.75-2.75-2.75H5.75ZM21 16V9.31c0-1.55-1.34-2.81-3-2.81H4.5V5.25C4.5 4.56 5.06 4 5.75 4h15.5c.69 0 1.25.56 1.25 1.25v9.5c0 .69-.56 1.25-1.25 1.25H21ZM1.5 18.69V9.3c0-.77.67-1.4 1.5-1.4h15c.83 0 1.5.63 1.5 1.4v9.38c0 .77-.67 1.4-1.5 1.4H3c-.83 0-1.5-.63-1.5-1.4Z">
            </path>
          </svg>
          <svg width="48" height="48" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M35 5.83333C18.9 5.83333 5.83331 18.9 5.83331 35C5.83331 51.1 18.9 64.1667 35 64.1667C51.1 64.1667 64.1666 51.1 64.1666 35C64.1666 18.9 51.1 5.83333 35 5.83333ZM32.9 52.5V49.4667C30.9458 49.175 29.4 48.5333 28.2333 47.775C26.775 46.8125 25.9875 45.7042 25.55 45.0333C24.9083 44.0125 25.2583 42.6417 26.25 42.0292C27.2708 41.3875 28.6416 41.6792 29.2833 42.7292C29.4875 43.0792 29.8666 43.6042 30.6541 44.1292C31.3833 44.5958 32.6375 45.1208 34.65 45.2375H35.0291C38.6166 45.1208 40.3958 42.9917 40.3958 41.1833C40.3958 40.6 40.2791 40.25 40.1041 39.9583C39.9291 39.6083 39.6083 39.2292 38.9375 38.7042C37.8875 37.8875 37.1 37.6542 35.3791 37.1L34.5625 36.8083L33.4833 36.5167L33.1916 36.4583C32.6958 36.3417 32.0833 36.1667 31.4416 35.9042C30.7125 35.6125 29.9541 35.2333 29.4 34.9125C29.0208 34.7083 28.6416 34.475 28.2916 34.2125C27.3291 33.4833 26.5416 32.6375 25.9875 31.5875C25.4333 30.5083 25.2291 29.4 25.2291 28.2333C25.2291 23.975 28.6708 20.9708 32.8125 20.0667V17.5C32.8125 16.3042 33.8041 15.3125 35 15.3125C36.1958 15.3125 37.1875 16.3042 37.1875 17.5V19.9792C39.1125 20.2708 40.6 20.8833 41.7666 21.6417C43.225 22.6042 44.0125 23.7417 44.45 24.3833C45.0916 25.4042 44.7416 26.775 43.75 27.4167C42.7291 28.0583 41.3583 27.7083 40.7166 26.7167C40.5125 26.3667 40.1333 25.8125 39.3458 25.3167C38.6166 24.8208 37.3625 24.2958 35.35 24.1792H35C31.4416 24.325 29.575 26.5125 29.575 28.2333C29.575 28.875 29.6916 29.2542 29.8666 29.575C30.0125 29.8667 30.3041 30.2458 30.8583 30.7125L30.975 30.8L31.5583 31.0917C32.025 31.3833 32.55 31.675 33.0166 31.85C33.3958 31.9958 33.775 32.1125 34.2708 32.2292L34.5041 32.2875L35.875 32.6667L36.6625 32.9C38.3541 33.4542 39.9 33.95 41.6208 35.2625C42.525 35.9917 43.3416 36.7792 43.925 37.8C44.5083 38.85 44.7416 39.9875 44.7416 41.1833C44.7416 45.4125 41.3875 48.475 37.2458 49.35V52.5C37.2458 53.6958 36.2541 54.6875 35.0583 54.6875C33.8333 54.6875 32.8708 53.6958 32.8708 52.5H32.9Z"
              fill="black" />
          </svg>
        </div>
        <span class="font-medium mt-3">Pessoa e Deals</span>
      </button>

    </div>
  </div>
</div>

<div [hidden]="step !== 3">
  <div class="w-full">

    <div class="flex justify-between">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
        <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
      </svg>
    </div>

    <h1 class="font-black text-[20px] text-black mt-2.5">Criar lead no Pipedrive</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Selecione um responsável. O envio das mensagem é opcional</h6>

    <div class="grid grid-cols-1 gap-6 max-w-[100%] mt-[25px]">
      <div class="flex flex-col items-center rounded-lg">

        <mat-select (selectionChange)="usersSelection($event)" class="input-field mb-4 p-2"
          placeholder="Selecione um Responsável">
          <mat-option *ngFor="let option of usersOptions" [value]="option">
            {{ option.label }}
          </mat-option>
        </mat-select>


        <form [formGroup]="formGroup" class="w-full">
          <div class="p-2 flex gap-2 rounded-lg start items-start w-full justify-start">
            <mat-slide-toggle color="primary" formControlName="sendMessageHistory">

              <div class="flex flex-col items-start p-2">
                <h1 class="text-base">Enviar conversa</h1>
                <small class="text-zinc-400 font-light text-sm">
                  Enviar histórico de conversa para o Pipedrive
                </small>
              </div>
            </mat-slide-toggle>
          </div>
        </form>

      </div>
      <div class="flex justify-center items-center w-full gap-2">
        <button
          class="h-[45px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
          (click)="clearValuesToBack()">
          Cancelar
        </button>
        <button class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] focus:outline-none w-[50%]
        hover:bg-[#350fca] active:bg-[#2a0a9c] focus:ring-2 focus:ring-blue-500" (click)="sendDataPersonLead()">
          <mat-icon *ngIf="loading" class="animate-spin">refresh</mat-icon>
          <span>{{ loading ? 'Enviando...' : 'Confirmar' }}</span>
        </button>

      </div>
    </div>
  </div>
</div>

<div [hidden]="step !== 4">
  <div class="w-full">

    <div class="flex justify-between">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
        <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
      </svg>
    </div>

    <h1 class="font-black text-[20px] text-black mt-2.5">Criar pessoa no Pipedrive</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Selecione um responsável. O envio das mensagem é opcional</h6>

    <div class="grid grid-cols-1 gap-6 max-w-[100%] mt-[25px]">
      <div class="flex flex-col items-center rounded-lg">

        <mat-select (selectionChange)="usersSelection($event)" class="input-field mb-4 p-2"
          placeholder="Selecione um Responsável">
          <mat-option *ngFor="let option of usersOptions" [value]="option">
            {{ option.label }}
          </mat-option>
        </mat-select>

        <form [formGroup]="formGroup" class="w-full">
          <div class="p-2 flex gap-2 rounded-lg start items-start w-full justify-start">
            <mat-slide-toggle color="primary" formControlName="sendMessageHistory">

              <div class="flex flex-col items-start p-2">
                <h1 class="text-base">Enviar conversa</h1>
                <small class="text-zinc-400 font-light text-sm">
                  Enviar histórico de conversa para o Pipedrive
                </small>
              </div>
            </mat-slide-toggle>
          </div>
        </form>

      </div>
      <div class="flex justify-center items-center w-full gap-2">
        <button
          class="h-[45px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
          (click)="clearValuesToBack()">Cancelar
        </button>
        <button class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] focus:outline-none w-[50%]
          hover:bg-[#350fca] active:bg-[#2a0a9c] focus:ring-2 focus:ring-blue-500" (click)="sendDataPerson()">
          <mat-icon *ngIf="loading" class="animate-spin">refresh</mat-icon>
          <span>{{ loading ? 'Enviando...' : 'Confirmar' }}</span>
        </button>
      </div>

    </div>
  </div>
</div>

<div [hidden]="step !== 5">
  <div class="w-full">
    <div class="flex justify-between">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
        <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
      </svg>
    </div>

    <h1 class="font-black text-[20px] text-black mt-2.5">Criar deal no Pipedrive</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Selecione uma pipeline, estágio e responsável. O envio das
      mensagens é opcional.</h6>

    <div class="grid grid-cols-1 gap-6 max-w-[100%] mt-[25px]">
      <div class="flex flex-col items-center rounded-lg">

        <mat-select class="input-field mb-[15px]" [(ngModel)]="selectedPipeline" (selectionChange)="stageSelect()"
          placeholder="Selecione um Pipeline">
          <mat-option *ngFor="let pipeline of pipelinesOptions" [value]="pipeline.value">
            {{ pipeline.label }}
          </mat-option>
        </mat-select>

        <mat-select class="input-field mb-[15px]" [(ngModel)]="selectedStage" [disabled]="!selectedPipeline"
          placeholder="Selecione um Estágio">
          <mat-option *ngFor="let stage of stagesOptions" [value]="stage.value">
            {{ stage.label }}
          </mat-option>
        </mat-select>

        <mat-select (selectionChange)="usersSelection($event)" class="input-field mb-4"
          placeholder="Selecione um Responsável">
          <mat-option *ngFor="let option of usersOptions" [value]="option">
            {{ option.label }}
          </mat-option>
        </mat-select>

        <form [formGroup]="formGroup" class="w-full">
          <div class="p-2 flex gap-2 rounded-lg start items-start w-full justify-start">
            <mat-slide-toggle color="primary" formControlName="sendMessageHistory">

              <div class="flex flex-col items-start p-2">
                <h1 class="text-base">Enviar conversa</h1>
                <small class="text-zinc-400 font-light text-sm">
                  Enviar histórico de conversa para o Pipedrive
                </small>
              </div>
            </mat-slide-toggle>
          </div>
        </form>

        <div class="flex justify-center  items-center w-full gap-2 mt-[60px]">
          <button
            class="h-[45px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
            (click)="clearValuesToBack()">Cancelar</button>
          <button class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] focus:outline-none w-[50%]
            hover:bg-[#350fca] active:bg-[#2a0a9c] focus:ring-2 focus:ring-blue-500" (click)="sendDataDeal()">
            <mat-icon *ngIf="loading" class="animate-spin">refresh</mat-icon>
            <span>{{ loading ? 'Enviando...' : 'Confirmar' }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div [hidden]="step !== 6">
  <div class="w-full">

    <div class="flex justify-between">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
        <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
      </svg>
    </div>

    <h1 class="font-black text-[20px] text-black mt-2.5">Criar pessoa e deal no Pipedrive</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Selecione uma pipeline, estágio e responsável. O envio das
      mensagens é opcional.</h6>

    <div class="grid grid-cols-1 gap-6 max-w-[100%] mt-[25px]">
      <div class="flex flex-col items-center rounded-lg">

        <mat-select class="input-field mb-[15px]" [(ngModel)]="selectedPipeline" (selectionChange)="stageSelect()"
          placeholder="Selecione um Pipeline">
          <mat-option *ngFor="let pipeline of pipelinesOptions" [value]="pipeline.value">
            {{ pipeline.label }}
          </mat-option>
        </mat-select>

        <!-- Stages Select (dependency the Pipeline) -->
        <mat-select class="input-field mb-[15px]" [(ngModel)]="selectedStage" [disabled]="!selectedPipeline"
          placeholder="Selecione um Estágio">
          <mat-option *ngFor="let stage of stagesOptions" [value]="stage.value">
            {{ stage.label }}
          </mat-option>
        </mat-select>

        <mat-select (selectionChange)="usersSelection($event)" class="input-field mb-4 p-2"
          placeholder="Selecione um Responsável">
          <mat-option *ngFor="let option of usersOptions" [value]="option">
            {{ option.label }}
          </mat-option>
        </mat-select>

        <form [formGroup]="formGroup" class="w-full">
          <div class="p-2 flex gap-2 rounded-lg start items-start w-full justify-start">
            <mat-slide-toggle color="primary" formControlName="sendMessageHistory">

              <div class="flex flex-col items-start p-2">
                <h1 class="text-base">Enviar conversa</h1>
                <small class="text-zinc-400 font-light text-sm">
                  Enviar histórico de conversa para o Pipedrive
                </small>
              </div>
            </mat-slide-toggle>
          </div>
        </form>

      </div>
      <div class="flex justify-center  items-center w-full gap-2 mt-[15px]">
        <button
          class="h-[45px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
          (click)="clearValuesToBack()">Cancelar</button>
        <button class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] focus:outline-none w-[50%]
          hover:bg-[#350fca] active:bg-[#2a0a9c] focus:ring-2 focus:ring-blue-500" (click)="sendDataPersonDeal()">
          <mat-icon *ngIf="loading" class="animate-spin">refresh</mat-icon>
          <span>{{ loading ? 'Enviando...' : 'Confirmar' }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
