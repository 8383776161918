import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactEditComponentForAttendance } from './contact-edit/contact-edit.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    ContactListComponent,
    ContactInfoComponent,
    ContactEditComponentForAttendance,
  ],
  exports: [
    ContactListComponent,
    ContactInfoComponent,
    ContactEditComponentForAttendance,
  ]
})
export class ContacttModule { }
