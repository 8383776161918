import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { AiAssistantActivatorSelectorComponent } from './ai-assistant-activator-selector.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    AiAssistantActivatorSelectorComponent,
  ],
  exports: [
    AiAssistantActivatorSelectorComponent,
  ],
  providers: [

  ]
})

export class AiAssistantActivatorSelectorModule { }
