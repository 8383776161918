<div [hidden]="step !== 0">
  <div class="w-full">

    <div class="flex justify-between">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
        <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
      </svg>
    </div>

    <h1 class="font-black text-[20px] text-black mt-2.5">Ações de CRM</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Selecione qual CRM você gostaria de manusear</h6>

    <div class="grid grid-cols-2 gap-6 w-[50%] items-center m-auto mt-[25px] mb-[25px]">
      <button
        class="flex flex-col items-center p-1 bg-white shadow-md hover:shadow-lg rounded-lg hover:bg-gray-50 transition-colors"
        (click)="openDialogCrm(1, attendance)" id="leadsButton"
        >
        <div class="flex flex-col items-center mb-2">
          <svg width="200px" height="150px" viewBox="0 0 304 304" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <path
                d="M59.6807,81.1772 C59.6807,101.5343 70.0078,123.4949 92.7336,123.4949 C109.5872,123.4949 126.6277,110.3374 126.6277,80.8785 C126.6277,55.0508 113.232,37.7119 93.2944,37.7119 C77.0483,37.7119 59.6807,49.1244 59.6807,81.1772 Z M101.3006,0 C142.0482,0 169.4469,32.2728 169.4469,80.3126 C169.4469,127.5978 140.584,160.60942 99.3224,160.60942 C79.6495,160.60942 67.0483,152.1836 60.4595,146.0843 C60.5063,147.5305 60.5374,149.1497 60.5374,150.8788 L60.5374,215 L18.32565,215 L18.32565,44.157 C18.32565,41.6732 17.53126,40.8873 15.07021,40.8873 L0.5531,40.8873 L0.5531,3.4741 L35.9736,3.4741 C52.282,3.4741 56.4564,11.7741 57.2508,18.1721 C63.8708,10.7524 77.5935,0 101.3006,0 Z"
                id="path-1">
              </path>
            </defs>
            <g id="Pipedrive_letter_logo_light" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Pipedrive_monogram_logo_light" transform="translate(67.000000, 44.000000)">
                <mask id="mask-2" fill="white">
                  <use href="#path-1">
                  </use>
                </mask>
                <use id="Clip-5" fill="#26292C" xlink:href="#path-1">
                </use>
              </g>
            </g>
          </svg>
          <span class="text-center font-medium text-lg">Pipedrive</span>
        </div>
      </button>
      <button
        class="flex flex-col items-center p-1 bg-white shadow-md hover:shadow-lg rounded-lg hover:bg-gray-50 transition-colors"
        (click)="openDialogCrm(1, attendance)" id="leadsButton">
        <div class="flex flex-col items-center mb-2">
          <img src="../../../../../../assets/images/agendor.svg" alt="Agendor" width="200px" height="150px"/>
          <span class="text-center font-medium text-lg">Agendor</span>
        </div>
      </button>

    </div>
  </div>
</div>