import { NgModule } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'lib-trend-core';
import { AttendancePanelChatComponent } from './attendance-panel-chat/attendance-panel-chat.component';
import { AiAssistantActivatorSelectorModule } from './attendance-panel-components/ai-assistant-activator-selector/ai-assistant-activator-selector.module';
import { AssignedUserModule } from './attendance-panel-components/assigned-user/assigned-user.module';
import { AttendanceContactCardModule } from './attendance-panel-components/attendance-contact-card/attendance-contact-card.module';
import { ChatModule } from './attendance-panel-components/chat/chat.module';
import { ContacttModule } from './attendance-panel-components/contact/contact.module';
import { CrmAttendanceModule } from './attendance-panel-components/crm-integrations/crm-integrations.module';
import { CustomFieldAttendanceModule } from './attendance-panel-components/custom-field-attendance/custom-field-attendance.module';
import { ContactNewConversationModule } from './attendance-panel-components/new-conversation/new-conversation.module';
import { NoteAttendanceModule } from './attendance-panel-components/note-attendance/note-attendance.module';
import { TagAttendanceModule } from './attendance-panel-components/tag-attendance/tag-attendance.module';
import { AttendancePanelInfoComponent } from './attendance-panel-info/attendance-panel-info.component';
import { AttendancePanelList } from './attendance-panel-list/attendance-panel-list.component';
import { AttendancePanelRoutingModule } from './attendance-panel-routing.module';
import { AttendancePanelComponent } from './attendance-panel.component';
import { Effects } from './state/effects';
import { attendanceReducers } from './state/reducers';

@NgModule({
  imports: [
    SharedModule,
    PickerModule,
    AttendancePanelRoutingModule,

    // components
    AssignedUserModule,
    AttendanceContactCardModule,
    ChatModule,
    ContacttModule,
    ContactNewConversationModule,
    NoteAttendanceModule,
    TagAttendanceModule,
    AiAssistantActivatorSelectorModule,
    // WaTemplateModule,
    CustomFieldAttendanceModule,
    CrmAttendanceModule,

    // ngrx
    EffectsModule.forFeature([Effects]),
    StoreModule.forFeature('attendanceState', attendanceReducers)
  ],
  declarations: [
    AttendancePanelComponent,
    AttendancePanelList,
    AttendancePanelChatComponent,
    AttendancePanelInfoComponent,
  ],
  exports: [
    AttendancePanelComponent,
    AttendancePanelList,
    AttendancePanelChatComponent,
    AttendancePanelInfoComponent,
  ]
})
export class AttendancePanelModule { }
