import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssistantObject } from '@trendbuild/trend-openai-api';
import { AbstractComponent, Attendance, AttendanceService, Integration, IntegrationService, IntegrationTypeEnum, OpenAIAssistant, OpenAIIntegration } from 'lib-trend-core';
import { map, takeUntil } from 'rxjs';

@Component({
  selector: 'service-ai-assistant-activator-selector',
  templateUrl: './ai-assistant-activator-selector.component.html',
})
export class AiAssistantActivatorSelectorComponent extends AbstractComponent implements OnInit {

  assistants: AssistantObject[] = [];
  filteredAssistants: OpenAIAssistant[] = [];
  integration: Integration;


  constructor(
    @Inject(IntegrationService) private integrationService: IntegrationService,
    public attendanceService: AttendanceService,
    public dialogRef: MatDialogRef<AiAssistantActivatorSelectorComponent>,
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public attendance: Attendance,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      activeIAModule: [this.attendance?.metadata?.integrations.ai?.actived || false],
      assistant: [this.attendance?.metadata?.integrations.ai?.assistant || null],
      integration: [this.attendance?.metadata?.integrations.ai?.integration || null]
    });
    this.getIntegrationById();
  }

  getIntegrationById(): void {
    this.integrationService.getList()
      .pipe(
        takeUntil(this.destroy$),
        map((integrations: Integration[]) =>
          integrations.map(integration => {
            if (integration.type === IntegrationTypeEnum.OPENAI) {
              const openAIMetadata = integration.metadata as OpenAIIntegration;
              const filteredAssistants = openAIMetadata.assistants?.filter(assistant =>
                assistant.channels.some(channel => channel._id === this.attendance.channel._id)
              ) || [];
              return {
                ...integration,
                metadata: {
                  ...openAIMetadata,
                  assistants: filteredAssistants
                }
              };
            }
            return integration;
          })
        )
      )
      .subscribe({
        next: (integrations: Integration[]) => {
          const openaiIntegration = integrations.find(integration =>
            integration.type === IntegrationTypeEnum.OPENAI &&
            (integration.metadata as OpenAIIntegration).assistants?.length
          );
          this.integration = openaiIntegration;
          if (openaiIntegration) {
            this.filteredAssistants = (openaiIntegration.metadata as OpenAIIntegration).assistants || [];
          } else {
            this.filteredAssistants = [];
          }
        },
        error: (err) => this.alertService.error(err.error.message)
      });
  }

  onSelectAssistant(selectedAssistantId: string): void {
    this.formGroup.patchValue({
      assistant: selectedAssistantId,
      integration: this.integration?._id || null
    });
  }

  confirm(): void {
    if (!this.formGroup.value.assistant) {
      this.alertService.error("Selecione um assistente.");
      return;
    }
    if (this.formGroup.valid) {
      this.dialogRef.close({
        ...this.formGroup.value,
        activeIAModule: true
      });
    }
  }



  cancel(): void {
    this.dialogRef.close(null);
  }
}