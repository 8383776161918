import { createReducer, on } from "@ngrx/store";
import { Attendance, Channel, CountState, Department, Integration, Message, Note, Pager, SettingsModule, Tag, User } from "lib-trend-core";
import { cloneDeep } from "lodash";
import * as AttendanceActions from "./actions";
import { AttendanceState } from "./app.state";

// initial global state
export const initialState: AttendanceState = {
  isLoading: false,
  company: undefined,
  user: undefined,
  attendance: undefined,
  attendances: new Pager<Attendance>(),
  notes: new Array<Note>(),
  tags: new Array<Tag>(),
  messages: new Pager<Message>(),
  error: null,
  pagerParams: undefined,
  integrations: new Array<Integration>(),
  settingsModules: new Array<SettingsModule>(),
  sidebarRight: false,
  contactInfo: false,
  counts: new Array<CountState>(),
  hasPipedriveIntegration: false,
  simpleTags: new Array<Tag>(),
  simpleDepartments: new Array<Department>(),
  simpleChannels: new Array<Channel>(),
  simpleUsers: new Array<User>(),
}

//reducers
export const attendanceReducers = createReducer(
  initialState,
  on(AttendanceActions.clearFullState, (state) => ({ ...cloneDeep(initialState) })),

  on(AttendanceActions.getCounts, (state, action) => ({ ...state, isLoading: true })),
  on(AttendanceActions.getAttendances, (state) => ({ ...state, isLoading: true })),
  on(AttendanceActions.getCounts, (state, action) => ({ ...state, isLoading: true })),
  on(AttendanceActions.getMessages, (state) => ({ ...state, isLoading: true })),
  on(AttendanceActions.getNotes, (state) => ({ ...state, isLoading: true })),
  on(AttendanceActions.getTags, (state) => ({ ...state, isLoading: true })),
  on(AttendanceActions.getIntegrations, (state) => ({ ...state, isLoading: true })),
  on(AttendanceActions.getSettingsModules, (state) => ({ ...state, isLoading: true })),

  on(AttendanceActions.setCounts, (state, action) => ({ ...state, isLoading: false, counts: action.counts })),
  on(AttendanceActions.setAttendances, (state, action) => ({ ...state, isLoading: false, attendances: action.attendances })),
  on(AttendanceActions.setAttendance, (state, action) => ({ ...state, isLoading: false, attendance: action.attendance })),
  on(AttendanceActions.setTags, (state, action) => ({ ...state, isLoading: false, tags: action.tags })),
  on(AttendanceActions.setNotes, (state, action) => ({ ...state, isLoading: false, notes: action.notes })),
  on(AttendanceActions.setMessages, (state, action) => ({ ...state, isLoading: false, messages: action.messages })),
  on(AttendanceActions.setPagerParams, (state, action) => ({ ...state, isLoading: false, pagerParams: action.pagerParams })),
  on(AttendanceActions.setIntegrations, (state, action) => ({ ...state, isLoading: false, integrations: action.integrations, hasPipedriveIntegration: action.hasPipedriveIntegration })),
  on(AttendanceActions.setSimpleLists, (state, action) => ({ ...state, isLoading: false, simpleDepartments: action.departaments, simpleChannels: action.channels, simpleTags: action.tags, simpleUsers: action.users })),
  on(AttendanceActions.setSettingsModules, (state, action) => ({ ...state, isLoading: false, settingsModules: action.settingsModules })),

  on(AttendanceActions.openCloseSidebarRight, (state, action) => ({ ...state, isLoading: false, sidebarRight: action.sidebarRight })),
  on(AttendanceActions.openCloseContactInfo, (state, action) => ({ ...state, isLoading: false, contactInfo: action.contactInfo })),

  // init
  on(AttendanceActions.setLoadInit, (state, action) => ({ ...state, user: action.user, company: action.company })),

  // failure
  on(AttendanceActions.setFailure, (state, action) => ({ ...state, error: action.error })),
);
